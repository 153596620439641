<template>
  <article
    class="terms_and_conditions">
    <div class="terms_and_conditions__parent">
      <section class="terms_and_conditions__container">

      <h3 class="terms_and_conditions__container--title">POLÍTICA DE PRIVACIDAD</h3>
      <div class="terms_and_conditions__container--content">
        <p>
          La presente Política de Privacidad establece los términos en que {{company}}
          usa y protege la información que es proporcionada por sus usuarios al
          momento de utilizar su aplicativo móvil. Esta compañía está comprometida con
          la seguridad de los datos de sus usuarios. Cuando le pedimos llenar los campos
          de información personal con la cual usted pueda ser identificado, lo hacemos
          asegurando que sólo se empleará de acuerdo con los términos de este
          documento. Sin embargo esta Política de Privacidad puede cambiar con el
          tiempo o ser actualizada por lo que le recomendamos y enfatizamos revisar
          continuamente esta página para asegurarse que está de acuerdo con dichos
          cambios.
          <br><br>
          <b>Información que es recogida</b><br><br>
          Nuestras Plataformas podrán recoger información personal por ejemplo:
          Nombre, información de contacto como su dirección de correo electrónica
          e información demográfica, datos de ubicación en primer y segundo plano,
          entre otros. Así mismo cuando sea necesario podrá ser requerida información
          específica para procesar algún pedido o realizar una entrega o facturación.
          <br><br>
          <b>Uso de la información recogida</b><br><br>
          Nuestro aplicativo móvil emplea la información con el fin de proporcionar el
          mejor servicio posible, particularmente para mantener un registro de usuarios, de
          pedidos en caso que aplique, y mejorar nuestros productos y servicios, analizar
          la transmisión de datos de consumo de clientes con el fin de identificar su
          trazabilidad de consumo, realizar análisis agregado de tendencias y patrones de
          consumo. Es posible que sean enviados correos electrónicos periódicamente a
          través de nuestro sitio con ofertas especiales, nuevos productos y otra
          información publicitaria que consideremos relevante para usted o que pueda
          brindarle algún beneficio, estos correos electrónicos serán enviados a la
          dirección que usted proporcione y podrán ser cancelados en cualquier momento.
          <br><br>
          <span v-if="have_geolocation">
            Los datos de geolocalización se utilizarán para referenciar y monitorear
            las geocercas establecidas por la plataforma, con el fin de establecer
            límites en los recorridos de los funcionarios (usuarios) asignados a las
            rondas de seguridad.
            <br><br>
          </span>
          Los datos personales recolectados también se utilizarán para procesos de
          admisión, selección y vinculación de contratistas, proveedores y clientes de
          Fusepong S.A.S. ; validación de la vinculación al sistema de seguridad social; y
          cualquier otro uso que se considere necesario para dar cumplimiento a la ley y a
          las obligaciones de carácter laboral.
          <br>
          Reporte a deudores que no cumplan con las obligaciones de pago a favor de
          Fusepong S.A.S. , para lo cual podrán permanecer en la base de datos que
          pueda administrar Fusepong S.A.S. o a la cual pueda ser reportado el deudor.
          <br>
          {{company}} está altamente comprometido para cumplir con el
          compromiso de mantener su información segura.
          <br><br>
          <b>Cookies</b><br><br>
          Una cookie se refiere a un fichero que es enviado con la finalidad de solicitar
          permiso para almacenarse en su ordenador, al aceptar dicho fichero se crea y la
          cookie sirve entonces para tener información respecto al tráfico web, y también
          facilita las futuras visitas a una web recurrente. Otra función que tienen las
          cookies es que con ellas las web pueden reconocerte individualmente y por tanto
          brindarte el mejor servicio personalizado de su web.
          <br><br>
          Nuestro sitio web y aplicativo móvil emplea las cookies para poder identificar las
          páginas que son visitadas y su frecuencia. Esta información es empleada
          únicamente para análisis estadístico y después la información se elimina de
          forma permanente. Usted puede eliminar las cookies en cualquier momento
          desde su ordenador. Sin embargo las cookies ayudan a proporcionar un mejor
          servicio de los sitios web, estás no dan acceso a información de su ordenador ni
          de usted, a menos de que usted así lo quiera y la proporcione directamente,
          visitas a una web . Usted puede aceptar o negar el uso de cookies, sin embargo
          la mayoría de navegadores aceptan cookies automáticamente pues sirve para
          tener un mejor servicio web. También usted puede cambiar la configuración de
          su ordenador para declinar las cookies. Si se declinan es posible que no pueda
          utilizar algunos de nuestros servicios.
          <br><br>
          <b>Enlaces a Terceros</b><br><br>
          Este aplicativo móvil pudiera contener enlaces a otros sitios que pudieran ser de
          su interés. Una vez que usted de clic en estos enlaces y abandone nuestra
          página, ya no tenemos control sobre al sitio al que es redirigido y por lo tanto no
          somos responsables de los términos o privacidad ni de la protección de sus
          datos en esos otros sitios terceros. Dichos sitios están sujetos a sus propias
          políticas de privacidad por lo cual es recomendable que los consulte para
          confirmar que usted está de acuerdo con estas.
          <br><br>
          <b>Control de su información personal</b><br><br>
          En cualquier momento usted puede restringir la recopilación o el uso de la
          información personal que es proporcionada a nuestro aplicativo móvil. En caso
          de que haya marcado la opción de recibir nuestro boletín o publicidad usted
          puede cancelarla en cualquier momento escribiendonos a
          <a href="mailto:soporte@fusepong.com"><span style="color: blue">soporte@fusepong.com</span></a>.
          <br><br>
          Esta compañía no venderá, cederá ni distribuirá la información personal que es
          recopilada sin su consentimiento, salvo que sea requerido por un juez con un
          orden judicial.
          <br><br>
          {{company}} se reserva el derecho de cambiar los términos de la
          presente Política de Privacidad en cualquier momento.
          <br><br>
          </p>
        </div>
      </section>
    </div>

  </article>
</template>

<script>
export default {
  data(){
    return{
      company:"Fuseaccess",
      have_geolocation: false
    }
  },
  mounted(){
    if(this.$route.query.company){
      this.company = this.$route.query.company
    }
    if(this.$route.query.geo){
      this.have_geolocation = this.$route.query.geo
    }
  }
}
</script>
